<template>
  <ReportIndex
    :fst-id="`reports-page`"
    :qso="qso"
    :endpoint="endpoint"
    :filterItems="filterOptions"
  />
</template>

<script>
import ReportIndex from '@/composites/report/index/Index.vue'
import { ReportConfig } from '@/config/ReportConfig'

export default {
  name: 'ReportsIndexPage',

  components: {
    ReportIndex,
  },
  data() {
    return {
      qso: { append: '', prepend: '' },
      endpoint: null,
      filterOptions: [
        {
          key: 'report_category',
          type: 'checkbox',
          input: [
            { text: 'Damaged Vehicle', value: 'DAMAGED_VEHICLE' },
            { text: 'Need Maintenance', value: 'NEED_MAINTENANCE' },
            { text: 'Wrongly Parked', value: 'WRONGLY_PARKED' },
            { text: 'Missing', value: 'MISSING' },
            { text: 'Out of service area', value: 'OUT_OF_SERVICE_AREA' },
            { text: 'Restricted area', value: 'RESTRICTED_AREA' },
            { text: 'Other', value: 'OTHER' },
          ],
          title: 'Category',
        },

        {
          key: 'report_status',
          type: 'checkbox',
          input: [
            { text: 'Attention Required', value: 'ATTENTION_REQUIRED' },
            { text: 'In Review', value: 'UNDER_PROGRESS' },
            { text: 'Resolved', value: 'RESOLVED' },
          ],
          title: 'Status',
        },
        {
          key: 'report_type',
          type: 'checkbox',
          input: [
            { text: 'Rental', value: 'rental' },
            { text: 'Trip', value: 'trip' },
          ],
          title: 'Type',
        },
      ],
    }
  },
  created() {
    this.generateEndPoint()
  },
  methods: {
    generateEndPoint() {
      this.endpoint =
        ReportConfig.api.index + `?fleet=${this.$route.params.id}&`
    },
  },
}
</script>
